import fetch from "../utils/fetch";


// vip列表
export function getVipList(data) {
  return fetch({
    url: '/api/mobile/leave-list',
    method: 'post',
    data: data
  });
}

// 公告信息
export function getNoticeList() {
  return fetch({
    url: '/api/mobile/random/list',
    method: 'get',
  });
}

// MatchRule
export function getMatchRule() {
    return fetch({
        url: '/api/mobile/match-rule',
        method: 'post',
    });
}
// 佣金详情
export function getCommissionInfo() {
  return fetch({
    url: '/api/mobile/home-commission',
    method: 'post',
  });
}
// 声明信息
export function getArticleList() {
  return fetch({
    url: '/api/mobile/article-list',
    method: 'post',
  });
}
// 轮播图
export function getBannerList() {
  return fetch({
    url: '/api/mobile/carousel-lists',
    method: 'post',
  });
}